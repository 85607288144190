import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class AggregatesApplicationsRepository extends BaseRepository {
    baseUrl = 'management/aggregates/aggregates-applications';


}
