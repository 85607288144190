import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class AssociationApplicationsRepository extends BaseRepository {
    baseUrl = 'management/aggregates/association-applications';

    /**
     * Get all applications associated to sector
     * @param sectorId
     */
    allFromSector(sectorId) {
        return this.httpClient.get(`${this.baseUrl}/all-from-sector/${sectorId}`);
    }
}
