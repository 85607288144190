import { inject }                            from 'aurelia-framework';
import { PLATFORM }                          from 'aurelia-pal';
import { BaseFormViewModel }                 from 'base-form-view-model';
import { FormSchema }                        from 'modules/management/aggregates/association-applications/form-schema';
import { AssociationApplicationsRepository } from 'modules/management/aggregates/association-applications/services/repository';
import { AppContainer }                      from 'resources/services/app-container';

@inject(AppContainer, AssociationApplicationsRepository, FormSchema)
export class EditAssociationApplication extends BaseFormViewModel {

    headerTitle = 'form.title.edit-record';
    formId      = 'management.aggregates.association-applications.edition-form';

    alert  = {};
    model  = {};
    schema = {};

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     */
    constructor(appContainer, repository, formSchema) {
        super(appContainer);

        this.repository = repository;
        this.formSchema = formSchema;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.aggregates.association-applications.manage',
            'management.aggregates.association-applications.edit',
        ]);
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('resources/views/default/button-form.html');
    }

    /**
     * Handles activate event
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    async activate(params) {
        super.activate();

        this.schema       = this.formSchema.schema(this);
        this.globalSchema = this.formSchema.globalSchema(this);
        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return await this.fetchData(params);
    }

    /**
     * Submits view form
     *
     * @returns {Promise}
     */
    submit() {
        this.alert = null;

        this.repository
            .update(this.model.id, this.model)
            .then((response) => {
                this.handleResponse(response, 'management.aggregates.association-applications.index');
                this.initialModel.assign(this.model);
            })
            .catch((error) => console.error(error));
    }

    /**
     * Fetches data from server
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    async fetchData(params) {
        const response = await this.repository.find(params.id);
        this.initialModel.assign(response);
        this.model.assign(response);
    }
}
